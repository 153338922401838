// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-articles-2014-07-01-the-journey-to-my-first-ios-app-md": () => import("./../../../src/articles/2014-07-01-the-journey-to-my-first-ios-app.md" /* webpackChunkName: "component---src-articles-2014-07-01-the-journey-to-my-first-ios-app-md" */),
  "component---src-articles-2014-07-13-toughts-on-stock-ui-md": () => import("./../../../src/articles/2014-07-13-toughts-on-stock-ui.md" /* webpackChunkName: "component---src-articles-2014-07-13-toughts-on-stock-ui-md" */),
  "component---src-articles-2014-07-15-tungsten-rounded-md": () => import("./../../../src/articles/2014-07-15-tungsten-rounded.md" /* webpackChunkName: "component---src-articles-2014-07-15-tungsten-rounded-md" */),
  "component---src-articles-2014-07-16-overcast-1-0-md": () => import("./../../../src/articles/2014-07-16-overcast-1-0.md" /* webpackChunkName: "component---src-articles-2014-07-16-overcast-1-0-md" */),
  "component---src-articles-2014-07-20-a-new-side-project-md": () => import("./../../../src/articles/2014-07-20-a-new-side-project.md" /* webpackChunkName: "component---src-articles-2014-07-20-a-new-side-project-md" */),
  "component---src-articles-2014-08-05-should-engineers-design-md": () => import("./../../../src/articles/2014-08-05-should-engineers-design.md" /* webpackChunkName: "component---src-articles-2014-08-05-should-engineers-design-md" */),
  "component---src-articles-2014-08-07-better-looking-bullet-placeholders-md": () => import("./../../../src/articles/2014-08-07-better-looking-bullet-placeholders.md" /* webpackChunkName: "component---src-articles-2014-08-07-better-looking-bullet-placeholders-md" */),
  "component---src-articles-2014-10-16-a-theory-about-the-next-ipads-and-side-by-side-apps-md": () => import("./../../../src/articles/2014-10-16-a-theory-about-the-next-ipads-and-side-by-side-apps.md" /* webpackChunkName: "component---src-articles-2014-10-16-a-theory-about-the-next-ipads-and-side-by-side-apps-md" */),
  "component---src-articles-2014-11-23-when-to-use-a-extend-when-to-use-a-mixin-md": () => import("./../../../src/articles/2014-11-23-when-to-use-a-extend-when-to-use-a-mixin.md" /* webpackChunkName: "component---src-articles-2014-11-23-when-to-use-a-extend-when-to-use-a-mixin-md" */),
  "component---src-articles-2014-12-04-pomplamoose-2014-tour-profits-md": () => import("./../../../src/articles/2014-12-04-pomplamoose-2014-tour-profits.md" /* webpackChunkName: "component---src-articles-2014-12-04-pomplamoose-2014-tour-profits-md" */),
  "component---src-articles-2014-12-07-everybody-scrolls-md": () => import("./../../../src/articles/2014-12-07-everybody-scrolls.md" /* webpackChunkName: "component---src-articles-2014-12-07-everybody-scrolls-md" */),
  "component---src-articles-2014-12-07-invalid-username-or-password-a-useless-security-measure-md": () => import("./../../../src/articles/2014-12-07-invalid-username-or-password-a-useless-security-measure.md" /* webpackChunkName: "component---src-articles-2014-12-07-invalid-username-or-password-a-useless-security-measure-md" */),
  "component---src-articles-2014-12-07-skolar-sans-md": () => import("./../../../src/articles/2014-12-07-skolar-sans.md" /* webpackChunkName: "component---src-articles-2014-12-07-skolar-sans-md" */),
  "component---src-articles-2014-12-09-my-ideal-setup-md": () => import("./../../../src/articles/2014-12-09-my-ideal-setup.md" /* webpackChunkName: "component---src-articles-2014-12-09-my-ideal-setup-md" */),
  "component---src-articles-2014-12-09-simplicity-md": () => import("./../../../src/articles/2014-12-09-simplicity.md" /* webpackChunkName: "component---src-articles-2014-12-09-simplicity-md" */),
  "component---src-articles-2014-12-11-designing-time-md": () => import("./../../../src/articles/2014-12-11-designing-time.md" /* webpackChunkName: "component---src-articles-2014-12-11-designing-time-md" */),
  "component---src-articles-2014-12-12-apple-watch-templates-now-include-a-sketch-version-md": () => import("./../../../src/articles/2014-12-12-apple-watch-templates-now-include-a-sketch-version.md" /* webpackChunkName: "component---src-articles-2014-12-12-apple-watch-templates-now-include-a-sketch-version-md" */),
  "component---src-articles-2014-12-15-awesome-videos-by-facebook-md": () => import("./../../../src/articles/2014-12-15-awesome-videos-by-facebook.md" /* webpackChunkName: "component---src-articles-2014-12-15-awesome-videos-by-facebook-md" */),
  "component---src-articles-2014-12-15-every-app-is-a-communications-app-md": () => import("./../../../src/articles/2014-12-15-every-app-is-a-communications-app.md" /* webpackChunkName: "component---src-articles-2014-12-15-every-app-is-a-communications-app-md" */),
  "component---src-articles-2014-12-21-lumia-gestures-md": () => import("./../../../src/articles/2014-12-21-lumia-gestures.md" /* webpackChunkName: "component---src-articles-2014-12-21-lumia-gestures-md" */),
  "component---src-articles-2014-12-27-mackenzie-child-on-learning-rails-md": () => import("./../../../src/articles/2014-12-27-mackenzie-child-on-learning-rails.md" /* webpackChunkName: "component---src-articles-2014-12-27-mackenzie-child-on-learning-rails-md" */),
  "component---src-articles-2014-12-29-dustin-senos-on-reducing-design-friction-md": () => import("./../../../src/articles/2014-12-29-dustin-senos-on-reducing-design-friction.md" /* webpackChunkName: "component---src-articles-2014-12-29-dustin-senos-on-reducing-design-friction-md" */),
  "component---src-articles-2014-12-30-my-favorite-movies-of-2014-md": () => import("./../../../src/articles/2014-12-30-my-favorite-movies-of-2014.md" /* webpackChunkName: "component---src-articles-2014-12-30-my-favorite-movies-of-2014-md" */),
  "component---src-articles-2015-01-05-ev-williams-on-metrics-md": () => import("./../../../src/articles/2015-01-05-ev-williams-on-metrics.md" /* webpackChunkName: "component---src-articles-2015-01-05-ev-williams-on-metrics-md" */),
  "component---src-articles-2015-01-06-jonnie-hallman-on-refactoring-code-md": () => import("./../../../src/articles/2015-01-06-jonnie-hallman-on-refactoring-code.md" /* webpackChunkName: "component---src-articles-2015-01-06-jonnie-hallman-on-refactoring-code-md" */),
  "component---src-articles-2015-01-07-excuse-a-day-md": () => import("./../../../src/articles/2015-01-07-excuse-a-day.md" /* webpackChunkName: "component---src-articles-2015-01-07-excuse-a-day-md" */),
  "component---src-articles-2015-01-07-interview-saturation-md": () => import("./../../../src/articles/2015-01-07-interview-saturation.md" /* webpackChunkName: "component---src-articles-2015-01-07-interview-saturation-md" */),
  "component---src-articles-2015-01-16-the-teehan-lax-partners-are-joining-facebook-md": () => import("./../../../src/articles/2015-01-16-the-teehan-lax-partners-are-joining-facebook.md" /* webpackChunkName: "component---src-articles-2015-01-16-the-teehan-lax-partners-are-joining-facebook-md" */),
  "component---src-articles-2015-07-11-custom-domains-with-heroku-md": () => import("./../../../src/articles/2015-07-11-custom-domains-with-heroku.md" /* webpackChunkName: "component---src-articles-2015-07-11-custom-domains-with-heroku-md" */),
  "component---src-articles-2015-07-15-i-gave-up-on-rss-md": () => import("./../../../src/articles/2015-07-15-i-gave-up-on-rss.md" /* webpackChunkName: "component---src-articles-2015-07-15-i-gave-up-on-rss-md" */),
  "component---src-articles-2015-07-21-one-day-before-md": () => import("./../../../src/articles/2015-07-21-one-day-before.md" /* webpackChunkName: "component---src-articles-2015-07-21-one-day-before-md" */),
  "component---src-articles-2015-08-02-some-speech-shuts-down-more-expression-than-it-opens-up-md": () => import("./../../../src/articles/2015-08-02-some-speech-shuts-down-more-expression-than-it-opens-up.md" /* webpackChunkName: "component---src-articles-2015-08-02-some-speech-shuts-down-more-expression-than-it-opens-up-md" */),
  "component---src-articles-2015-08-14-two-degrees-md": () => import("./../../../src/articles/2015-08-14-two-degrees.md" /* webpackChunkName: "component---src-articles-2015-08-14-two-degrees-md" */),
  "component---src-articles-2015-09-12-failure-is-like-learning-to-ride-a-bike-md": () => import("./../../../src/articles/2015-09-12-failure-is-like-learning-to-ride-a-bike.md" /* webpackChunkName: "component---src-articles-2015-09-12-failure-is-like-learning-to-ride-a-bike-md" */),
  "component---src-articles-2015-12-05-our-best-meetup-so-far-md": () => import("./../../../src/articles/2015-12-05-our-best-meetup-so-far.md" /* webpackChunkName: "component---src-articles-2015-12-05-our-best-meetup-so-far-md" */),
  "component---src-articles-2015-12-31-my-2015-md": () => import("./../../../src/articles/2015-12-31-my-2015.md" /* webpackChunkName: "component---src-articles-2015-12-31-my-2015-md" */),
  "component---src-articles-2016-02-09-book-recommendations-from-twitter-md": () => import("./../../../src/articles/2016-02-09-book-recommendations-from-twitter.md" /* webpackChunkName: "component---src-articles-2016-02-09-book-recommendations-from-twitter-md" */),
  "component---src-articles-2016-02-21-generalist-vs-specialist-md": () => import("./../../../src/articles/2016-02-21-generalist-vs-specialist.md" /* webpackChunkName: "component---src-articles-2016-02-21-generalist-vs-specialist-md" */),
  "component---src-articles-2016-03-25-lingo-md": () => import("./../../../src/articles/2016-03-25-lingo.md" /* webpackChunkName: "component---src-articles-2016-03-25-lingo-md" */),
  "component---src-articles-2016-03-25-the-empathy-machine-md": () => import("./../../../src/articles/2016-03-25-the-empathy-machine.md" /* webpackChunkName: "component---src-articles-2016-03-25-the-empathy-machine-md" */),
  "component---src-articles-2016-03-25-the-veil-of-ignorance-md": () => import("./../../../src/articles/2016-03-25-the-veil-of-ignorance.md" /* webpackChunkName: "component---src-articles-2016-03-25-the-veil-of-ignorance-md" */),
  "component---src-articles-2016-04-04-we-vs-they-md": () => import("./../../../src/articles/2016-04-04-we-vs-they.md" /* webpackChunkName: "component---src-articles-2016-04-04-we-vs-they-md" */),
  "component---src-articles-2016-07-30-the-heart-of-the-builder-md": () => import("./../../../src/articles/2016-07-30-the-heart-of-the-builder.md" /* webpackChunkName: "component---src-articles-2016-07-30-the-heart-of-the-builder-md" */),
  "component---src-articles-2016-12-10-that-time-one-of-my-tweets-reached-33-k-retweets-md": () => import("./../../../src/articles/2016-12-10-that-time-one-of-my-tweets-reached-33k-retweets.md" /* webpackChunkName: "component---src-articles-2016-12-10-that-time-one-of-my-tweets-reached-33-k-retweets-md" */),
  "component---src-articles-2019-gift-guide-index-md": () => import("./../../../src/articles/2019-gift-guide/index.md" /* webpackChunkName: "component---src-articles-2019-gift-guide-index-md" */),
  "component---src-articles-amazing-fan-art-for-the-force-awakens-index-md": () => import("./../../../src/articles/amazing-fan-art-for-the-force-awakens/index.md" /* webpackChunkName: "component---src-articles-amazing-fan-art-for-the-force-awakens-index-md" */),
  "component---src-articles-antifragile-design-at-cocoaheads-index-md": () => import("./../../../src/articles/antifragile-design-at-cocoaheads/index.md" /* webpackChunkName: "component---src-articles-antifragile-design-at-cocoaheads-index-md" */),
  "component---src-articles-brydge-mini-ii-review-index-md": () => import("./../../../src/articles/brydge-mini-ii-review/index.md" /* webpackChunkName: "component---src-articles-brydge-mini-ii-review-index-md" */),
  "component---src-articles-clockwise-322-index-md": () => import("./../../../src/articles/clockwise-322/index.md" /* webpackChunkName: "component---src-articles-clockwise-322-index-md" */),
  "component---src-articles-design-details-196-index-md": () => import("./../../../src/articles/design-details-196/index.md" /* webpackChunkName: "component---src-articles-design-details-196-index-md" */),
  "component---src-articles-design-is-index-md": () => import("./../../../src/articles/design-is/index.md" /* webpackChunkName: "component---src-articles-design-is-index-md" */),
  "component---src-articles-designed-by-few-2016-md": () => import("./../../../src/articles/designed-by-few-2016.md" /* webpackChunkName: "component---src-articles-designed-by-few-2016-md" */),
  "component---src-articles-firewatch-themed-editor-index-md": () => import("./../../../src/articles/firewatch-themed-editor/index.md" /* webpackChunkName: "component---src-articles-firewatch-themed-editor-index-md" */),
  "component---src-articles-her-story-index-md": () => import("./../../../src/articles/her-story/index.md" /* webpackChunkName: "component---src-articles-her-story-index-md" */),
  "component---src-articles-hide-your-dock-without-losing-your-mind-index-md": () => import("./../../../src/articles/hide-your-dock-without-losing-your-mind/index.md" /* webpackChunkName: "component---src-articles-hide-your-dock-without-losing-your-mind-index-md" */),
  "component---src-articles-on-the-apple-watch-companion-app-index-md": () => import("./../../../src/articles/on-the-apple-watch-companion-app/index.md" /* webpackChunkName: "component---src-articles-on-the-apple-watch-companion-app-index-md" */),
  "component---src-articles-operator-index-md": () => import("./../../../src/articles/operator/index.md" /* webpackChunkName: "component---src-articles-operator-index-md" */),
  "component---src-articles-pizza-index-md": () => import("./../../../src/articles/pizza/index.md" /* webpackChunkName: "component---src-articles-pizza-index-md" */),
  "component---src-articles-sharpie-art-workshop-index-md": () => import("./../../../src/articles/sharpie-art-workshop/index.md" /* webpackChunkName: "component---src-articles-sharpie-art-workshop-index-md" */),
  "component---src-articles-shopify-email-index-md": () => import("./../../../src/articles/shopify-email/index.md" /* webpackChunkName: "component---src-articles-shopify-email-index-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-item-jsx": () => import("./../../../src/pages/about/AboutItem.jsx" /* webpackChunkName: "component---src-pages-about-about-item-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-speaking-index-jsx": () => import("./../../../src/pages/speaking/index.jsx" /* webpackChunkName: "component---src-pages-speaking-index-jsx" */),
  "component---src-pages-work-climate-index-jsx": () => import("./../../../src/pages/work/climate/index.jsx" /* webpackChunkName: "component---src-pages-work-climate-index-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-pages-work-layout-index-jsx": () => import("./../../../src/pages/work/layout/index.jsx" /* webpackChunkName: "component---src-pages-work-layout-index-jsx" */),
  "component---src-pages-work-order-status-page-index-jsx": () => import("./../../../src/pages/work/order-status-page/index.jsx" /* webpackChunkName: "component---src-pages-work-order-status-page-index-jsx" */),
  "component---src-pages-work-shopify-apple-watch-index-jsx": () => import("./../../../src/pages/work/shopify-apple-watch/index.jsx" /* webpackChunkName: "component---src-pages-work-shopify-apple-watch-index-jsx" */),
  "component---src-pages-work-shopify-pay-landing-index-jsx": () => import("./../../../src/pages/work/shopify-pay-landing/index.jsx" /* webpackChunkName: "component---src-pages-work-shopify-pay-landing-index-jsx" */),
  "component---src-pages-work-tiny-conf-index-jsx": () => import("./../../../src/pages/work/tiny-conf/index.jsx" /* webpackChunkName: "component---src-pages-work-tiny-conf-index-jsx" */),
  "component---src-pages-work-tipsy-index-jsx": () => import("./../../../src/pages/work/tipsy/index.jsx" /* webpackChunkName: "component---src-pages-work-tipsy-index-jsx" */),
  "component---src-pages-work-weatherline-index-jsx": () => import("./../../../src/pages/work/weatherline/index.jsx" /* webpackChunkName: "component---src-pages-work-weatherline-index-jsx" */),
  "component---src-templates-articles-jsx": () => import("./../../../src/templates/articles.jsx" /* webpackChunkName: "component---src-templates-articles-jsx" */)
}

